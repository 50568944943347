<template>
  <default-layout>
    <div class="borrower-address mt-5 px-6">
      <h2>Địa chỉ cư trú</h2>
      <div class="mt-5 required relative">
        <base-auto-complete
          name="province"
          placeholder="Thành phố"
          v-model:modelValue="province"
          v-model:modelId="provinceId"
          :data="arrProvince"
        />
        <base-text-error :errorMessages="errorProvince" />
      </div>
      <div class="flex items-center justify-between mt-3">
        <div class="required relative">
          <base-auto-complete
            name="district"
            placeholder="Quận/huyện"
            v-model:modelValue="district"
            v-model:modelId="districtId"
            :data="arrDistrict"
            :disabled="!provinceId"
          />
          <base-text-error :errorMessages="errorDistrcit" />
        </div>
        <div class="required relative">
          <base-auto-complete
            name="ward"
            placeholder="Phường/xã"
            v-model:modelValue="ward"
            v-model:modelId="wardId"
            :disabled="!districtId"
            :data="arrWard"
          />
          <base-text-error :errorMessages="errorWard" />
        </div>
      </div>
      <div class="mt-5 required relative">
        <base-input placeholder="Đường" v-model:modelValue="street" />
        <base-text-error :errorMessages="errorStreet" />
      </div>
      <div class="mt-5">
        <base-input placeholder="Số nhà" v-model:modelValue="houseNumber" />
      </div>
      <base-button
        class="w-full border-16 bg-tertiary text-white my-10 py-6"
        @click="handleSubmit"
      >
        Tiếp tục
      </base-button>
    </div>
  </default-layout>
</template>
<script lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseAutoComplete from '@/components/Base/BaseAutoComplete.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import constRouter from '@/constants/constRouter';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import { required } from '@/helpers/validate';
import { getProvinces, getDistrict, getWard } from '@/apis/ApiGeneral';
import { defineComponent } from 'vue';
import { updateCustomer } from '@/apis/ApiOnboarding';
import { mapGetters, mapActions } from 'vuex';
export default defineComponent({
  components: {
    DefaultLayout,
    BaseAutoComplete,
    BaseButton,
    BaseInput,
    BaseTextError,
  },
  name: 'BorrowerAddress',
  data() {
    return {
      province: '',
      provinceId: '',
      district: '',
      districtId: '',
      ward: '',
      wardId: '',
      street: '',
      houseNumber: '',
      errorStreet: '',
      errorProvince: '',
      errorWard: '',
      errorDistrcit: '',
      arrProvince: [],
      arrDistrict: [],
      arrWard: [],
    };
  },
  created() {
    this.getProvice();
    this.provinceId = this.getUser.address?.province.id;
    this.districtId = this.getUser.address?.district.id;
    this.wardId = this.getUser.address?.ward.id;
    this.province = this.getUser.address?.province.name;
    this.district = this.getUser.address?.district.name;
    this.ward = this.getUser.address?.ward.name;
    this.street = this.getUser.address?.street;
    this.houseNumber = this.getUser.address?.houseNumber;
  },
  computed: {
    ...mapGetters({
      getUser: 'getUser',
    }),
  },
  watch: {
    provinceId(value) {
      if (value) {
        this.districtId = '';
        this.district = '';
        getDistrict(value)
          .then(res => (this.arrDistrict = res.data.data))
          .catch(err => console.log(err));
      }
    },
    districtId(value) {
      this.ward = '';
      this.wardId = '';
      if (value) {
        getWard(value)
          .then(res => (this.arrWard = res.data.data))
          .catch(err => console.log(err));
      }
    },
  },
  methods: {
    ...mapActions({
      fetchUserInfo: 'fetchUserInfo',
    }),
    getProvice() {
      getProvinces()
        .then(res => {
          this.arrProvince = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    validation() {
      this.errorProvince = required('Province', this.province);
      this.errorDistrcit = required('District', this.district);
      this.errorWard = required('Ward', this.ward);
      this.errorStreet = required('Street', this.street);

      if (!this.province || !this.district || !this.ward || !this.street) {
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.validation()) {
        updateCustomer('', {
          provinceId: this.provinceId,
          districtId: this.districtId,
          wardId: this.wardId,
          street: this.street,
          houseNumber: this.houseNumber,
        });
        this.fetchUserInfo();
        this.$router.push({ name: constRouter.BORROWER_COMPANY_ADDRESS.name });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.required::before {
  content: '*';
  color: red;
  display: block;
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
}
</style>
